@import "../global/mixins";

.bannerImage {
	position: relative;
	width: 100%;
	aspect-ratio: 3 / 1;

	span {
		display: block;
		aspect-ratio: 3 / 1;
	}
}

.head {

	h1 {
		margin: getRem(56) 0 getRem(24) 0;
	}
}

.wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: getRem(90) getRem(50);
	margin-top: getRem(90);

	@include breakpoint(mobile-tablet) {
        max-width: 100%;
    }

	:global {	
		.imageItem {

			& > .imageContainer {
				display: block;
				width: 100%;
				aspect-ratio: 1.75 / 1;
		
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

	& > div {
		overflow: hidden;
		
		&[data-size="20"] {
			flex: 0 0 calc(20% - getRem(50));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(50));
            }

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="25"] {
			flex: 0 0 calc(25% - getRem(50));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(50));
            }

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="33"] {
			flex: 0 0 calc(33% - getRem(50));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(50));
            }

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="50"] {
			flex: 0 0 calc(50% - getRem(50));

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="66"] {
			flex: 0 0 calc(66% - getRem(50));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(50));
            }

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="75"] {
			flex: 0 0 calc(75% - getRem(50));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(50));
            }

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="80"] {
			flex: 0 0 calc(80% - getRem(50));

            @include breakpoint(tablet) {
				flex: 0 0 calc(50% - getRem(50));
            }

            @include breakpoint(mobile) {
				flex: 0 0 100%;
            }
        }

        &[data-size="100"] {
			flex: 0 0 100%;

            .imageContainer {
                img {
                    width: auto;
                    max-width: 100%;
                }
            }
        }
	}
}