@import "../global/mixins";

.mediaCard {
    background: var(--white);
    box-shadow: 0px getRem(2) getRem(12) rgba(0, 0, 0, 0.1);
    border-radius: getRem(8);
    display: flex;
    flex-direction: column;
    position: relative;

    &.unread {
        border-top: getRem(4) solid var(--red);
        // padding-top: getRem(12);
    }

    .overlayButton {
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .imageContainer {
        width: 100%;
        height: auto;
        aspect-ratio: 1.64;
        border-radius: getRem(8);
        overflow: hidden;
        position: relative;

        span {
            width: 100% !important;
            height: 100% !important;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;

            path {
                stroke: #FFFFFF;
                fill: #FFFFFF;
            }

            circle {
                stroke: #FFFFFF;
                fill: rgba(255, 255, 255, .2);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .2s ease-in-out;
        }

        .playButton {
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;

            &:hover,
            &:focus {
                &+span img {
                    transform: scale(1.025);
                }
            }
        }
    }

    .textContainer {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 getRem(16) getRem(16);

        h3,
        .title {
            @include font(20, 24);
            font-weight: 700;
            margin: getRem(12) 0 0;

            a {

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        button {
            background: none;
            border: none;
            color: var(--red);
            cursor: pointer;
            display: inline-block;
            @include font(16, 24);
            font-weight: 700;
            margin: getRem(8) auto 0 0;
            overflow: hidden;
            padding: 0;
            position: relative;

            &:hover {
                text-decoration: underline;
            }

            &:focus {
                border: 1px solid var(--red);
            }

            &.videoModalButton {
                width: getRem(74);
                height: getRem(24);
                color: transparent;

                &:hover {
                    &:before {
                        text-decoration: underline;
                    }
                }

                &:before {
                    content: attr(data-text);
                    width: 100%;
                    height: 100%;
                    color: var(--red);
                    @include font(16, 24);
                    font-weight: bold;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
            }

            // &.galleryModalButton {
            //     width: getRem(200);
            //     height: getRem(24);
            // }
        }
    }
}