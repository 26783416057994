@import "../global/mixins";

.banner {
	display: flex;
	justify-content: space-between;
	gap: getRem(75);
	margin-bottom: getRem(100);
	padding-top: getRem(80);
}

.bannerContent {
	flex: 1;
}

.bannerTitle {
	margin: 0 0 getRem(32) 0;
}

.bannerTag {
	@include font(24, 36);
	font-weight: 700;
	color: #DB0007;
	margin: 0 0 getRem(12) 0;
}

.bannerImage {
	position: relative;
	width: 100%;
	flex: 1.2;
}