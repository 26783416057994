@import "../global/mixins";

.award {
    display: flex;
    align-items: center;
    // flex-shrink: 0;
    margin: 0 0 auto;

    .iconContainer {
        width: getRem(24);
        height: getRem(24);
        border-radius: 50%;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
    }

    .awardDetails {
        margin-left: getRem(6);

        p {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            text-align: left;
            margin: 0;
        }

        .year {
            // color: rgba(#2D2D2D, .8);
            @include font(11, 14);
        }

        .awardLabel {
            @include font(14, 15);
            font-weight: 700;
        }
    }
}