@import "../global/mixins";

.briefsCard {
    background: var(--white);
    box-shadow: 0px getRem(2) getRem(12) rgba(0, 0, 0, 0.1);
    border-radius: 0 0 getRem(8) getRem(8);
    display: flex;
    flex-direction: column;

    &.unread {
        border-top: getRem(4) solid var(--red);
        // padding-top: getRem(12);
    }

    h3 {
        margin: getRem(4) 0 0;

        a {
            color: var(--text-color) !important;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}

.imageContainer {
    width: 100%;
    height: auto;
    aspect-ratio: 1.64;
    position: relative;

    @include breakpoint(mobile) {
        aspect-ratio: 4/3;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    a {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;

        // &.hasAward {
        //     border: getRem(4) solid #494949;
        //     border-top: none;
        // }

        // .awardLabel {
        //     width: 100%;
        //     background: #494949;
        //     color: var(--white);
        //     @include font(14, 14);
        //     font-weight: 700;
        //     padding: getRem(4) 0;
        //     position: absolute;
        //     text-align: center;
        //     text-transform: uppercase;
        //     top: 0;
        //     left: 0;
        //     z-index: 1;
        // }
    }

    // :global {
    //     .finalist {
    //         border-color: var(--red) !important;

    //         .awardLabel {
    //             background: var(--red) !important;
    //         }
    //     }

    //     .gold {
    //         border-color: #FFBC0D !important;

    //         .awardLabel {
    //             background: #FFBC0D !important;
    //             // color: var(--black) !important;
    //         }
    //     }

    //     .silver {
    //         border-color: #B1B5BC !important;

    //         .awardLabel {
    //             background: #B1B5BC !important;
    //             // color: var(--black) !important;
    //         }
    //     }

    //     .bronze {
    //         border-color: #7D4D05 !important;

    //         .awardLabel {
    //             background: #7D4D05 !important;
    //         }
    //     }
    // }

    .newBadge {
        background: transparent;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        span {
            color: var(--white);
            display: inline-block;
            @include font(12, 12);
            font-weight: 600;
            padding: getRem(2) getRem(6) 0;
            position: relative;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-top: getRem(36) solid red;
                border-right: getRem(64) solid transparent;
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }
    }
}

.textContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: getRem(16);
}

.actionsContainer {
    display: flex;
    align-items: center;
    margin-top: getRem(12);
    padding-bottom: getRem(12);

    .viewCount {
        display: flex;
        align-items: center;
        margin: 0 getRem(16) 0 0;

        svg {
            flex-shrink: 0;
        }

        span {
            color: var(--grey);
            @include font(14, 29);
            margin-left: getRem(4);
        }
    }

    .bookmarkButtonContainer {
        button {
            background: transparent;
            border: none;
            cursor: pointer;
            margin-right: getRem(16);
            padding: 0;
            transition: filter 0.33s cubic-bezier(0.4, 0, 0.2, 1);

            &:hover {
                filter: drop-shadow(0px 1px 6px #ffbc0d);
            }

            svg {
                path {
                    fill: transparent;
                }
            }

            span {
                display: none;
                ;
            }
        }
    }
}

.metaContainer {
    // display: grid;
    // grid-template-columns: repeat(3, auto);
    // grid-column-gap: getRem(16);
    border-top: getRem(1) solid rgba(#ADADAD, .5);
    display: flex;
    padding: getRem(14) 0 0;
    column-gap: getRem(16);
    row-gap: getRem(8);

    p {
        display: flex;
        align-items: flex-start;
        margin: 0;
        // margin: 0 getRem(16) 0 0;


        &:last-child {
            margin-right: 0;
        }

        svg {
            height: getRem(18);
            flex-shrink: 0;

            path {
                fill: rgba(#2D2D2D, .8);
            }
        }

        span {
            color: rgba(#2D2D2D, .8);
            @include font(14, 18);
            margin-top: getRem(-1);
            margin-left: getRem(8);
        }

        &.cat {
            margin-right: 0;
            overflow: hidden;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .categoryContainer {
        max-width: 100%;
        min-width: 0;
        display: inline-flex;
        position: relative;

        &:hover {
            .metaHover {
                display: inline-block;
            }
        }

        .metaHover {
            width: 100%;
            min-width: getRem(124);
            background: #FFFFFF;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
            color: black;
            display: none;
            padding: getRem(16);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;

            .hoverLabel {
                color: #292929;
                @include font(14, 18);
                margin: 0;

                span {
                    @include font(14, 18);
                    text-transform: uppercase;
                }

                svg {
                    margin-right: getRem(4);
                }
            }

            ul {
                list-style: none;
                margin: getRem(10) 0 0;
                padding: 0;

                li {
                    // color: #006BAE;
                    @include font(14, 18);
                    margin-top: getRem(4) !important;
                    // white-space: nowrap;

                    a {
                        color: #006BAE;
                        font-weight: normal;
                        text-transform: none;
                        white-space: break-spaces;
                    }
                }
            }

            svg path {
                fill: #2D2D2D;
            }
        }
    }
}