// Target only IE
@mixin targetIE() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

// Target only High-DPI displays
@mixin targetRetina {
    /* 1.5 dpr */
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
        @content;
    }
}

// Material Design Easing Curves
@function md-ease($curve: "ease-in-out") {
    @if $curve== "ease-in-out" {
        @return cubic-bezier(0.4, 0, 0.2, 1);
    } @else if $curve== "ease-in" {
        @return cubic-bezier(0.4, 0, 1, 1);
    } @else if $curve== "ease-out" {
        @return cubic-bezier(0, 0, 0.2, 1);
    }
}

// Material Design Shadows
@mixin md-shadow($level: 3, $color: $c-text) {
    @if $level==1 {
        box-shadow: 0 2px 2px 0 rgba($color, 0.14),
            0 3px 1px -2px rgba($color, 0.12), 0 1px 5px 0 rgba($color, 0.2);
    } @else if $level==2 {
        box-shadow: 0 4px 5px 0 rgba($color, 0.14),
            0 1px 10px 0 rgba($color, 0.12), 0 2px 4px -1px rgba($color, 0.3);
    } @else if $level==3 {
        box-shadow: 0 8px 17px 2px rgba($color, 0.14),
            0 3px 14px 2px rgba($color, 0.12), 0 5px 5px -3px rgba($color, 0.2);
    } @else if $level==4 {
        box-shadow: 0 16px 24px 2px rgba($color, 0.14),
            0 6px 30px 5px rgba($color, 0.12), 0 8px 10px -7px rgba($color, 0.2);
    } @else if $level==5 {
        box-shadow: 0 24px 38px 3px rgba($color, 0.14),
            0 9px 46px 8px rgba($color, 0.12),
            0 11px 15px -7px rgba($color, 0.2);
    }
}

$desktop-max: 1440px;

// Responsive Breakpoints

$breakpoints: sm 800px 800px, md 1000px 1000px, lg 1100px 1100px,
    xlg 1600px 1600px !default;
$breakpoint-sm: 700px;
$breakpoint-md: 1000px;
$breakpoint-lg: 1100px;
$breakpoint-xlg: 1600px;

@mixin breakpoint($size) {
    @if $size==mobile {
        @media screen and (max-width:#{$breakpoint-sm - 1}) {
            @content;
        }
    } @else if $size==iphone {
        @media screen and (min-width:370px) and (max-width:#{$breakpoint-sm - 1}) {
            @content;
        }
    }

    @if $size==mobile-portrait {
        @media screen and (max-width:#{$breakpoint-md}) {
            @content;
        }
    } @else if $size==tablet {
        @media screen and (min-width:#{$breakpoint-sm}) and (max-width:#{$breakpoint-lg - 1}) {
            @content;
        }
    } @else if $size==tablet-landscape {
        @media screen and (min-width:#{$breakpoint-md + 100}) and (max-width:#{$breakpoint-lg - 1}) {
            @content;
        }
    } @else if $size==tablet-portrait {
        @media screen and (min-width:#{$breakpoint-sm }) and (max-width:#{$breakpoint-md + 100}) {
            @content;
        }
    } @else if $size==mobile-tablet {
        @media screen and (max-width:#{$breakpoint-lg - 1}) {
            @content;
        }
    } @else if $size==tablet-desktop {
        @media screen and (min-width:#{$breakpoint-sm}) {
            @content;
        }
	} @else if $size==laptop {
        @media screen and (max-width:#{$breakpoint-md}) {
            @content;
        }
    } @else if $size==desktop {
        @media screen and (min-width:#{$breakpoint-lg}) {
            @content;
        }
    }  @else if $size==desktop-min {
        @media screen and (min-width:#{$breakpoint-lg}) and (max-width: #{$desktop-max}-1px) {
            @content;
        }
    } @else if $size==xlg {
        @media screen and (min-width:#{$breakpoint-xlg}) {
            @content;
        }
    } @else if $size==desktop-max {
        @media screen and (min-width:#{$desktop-max}) {
            @content;
        }
    }
}

@mixin img_container($width, $height) {
    width: 100%;
    height: 0px;
    padding-bottom: #{($height / $width) * 100%};
    position: relative;
    display: block;
    > img,
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    img {
        z-index: 2;
    }
    iframe {
        z-index: 1;
    }
}

@mixin lineHeightRatio($font-size, $line-height) {
    line-height: #{($line-height / $font-size)}em;
}

// Modified from Mike's / Angie's / Sarah's solution
@mixin font($size, $line-height) {
    font-size: getRem($size);
    @include lineHeightRatio($size, $line-height);
}

@mixin debug {
    background-color: rgba(hotpink, 0.25);
    outline: hotpink;
}

// REM converter function
$rem: 16;
@function getRem($val) {
    @return ($val/$rem) + rem;
}

.hide-mobile {
    @include breakpoint(mobile) {
        display: none !important;
    }
}
.hide-desktop {
    @include breakpoint(desktop) {
        display: none;
    }
}
.show-mobile {
    @include breakpoint(tablet-desktop) {
        display: none !important;
    }
}
.show-desktop {
    @include breakpoint(mobile-tablet) {
        display: none;
    }
}

.show-tablet {
    @include breakpoint(mobile) {
        display: none;
    }
    @include breakpoint(desktop) {
        display: none;
    }
}
.hide-tablet {
    @include breakpoint(tablet) {
        display: none;
    }
}
