@import "../global/mixins";

div.gridPage {
    margin-top: calc(var(--nav-height));
    padding-bottom: getRem(104);

    @include breakpoint(mobile) {
        padding-bottom: 0;
    }
}

.wrapper {
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--padding);
	padding-right: var(--padding);

	:global {
		.image_resized {

			img {
				display: block;
				width: 100%;
			}
		}

		.image-style-full {
			margin-inline: auto;
		}

		.image-style-align-left {
			margin-right: auto;
		}

		.image-style-align-right {
			margin-left: auto;
		}
	}
}

.banner {
    padding: getRem(48) 0 getRem(32);

    .breadcrumbs {
        list-style: none;
        margin: 0;
        padding: 0;

        li,
        a {
            color: #6F6F6F;
            @include font(14, 21);
            letter-spacing: getRem(2);
            text-transform: uppercase;
        }

        li {
            display: inline-block;
            vertical-align: middle;
            position: relative;

            &:not(:last-child) {
                &:after {
                    content: '/';
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: getRem(6);
                    margin-right: getRem(8);
                }
            }
        }

        a {
            font-weight: 700;
        }
    }

    .textContainer {
        width: 75%;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

	.ctaContainer {
		margin-top: getRem(40);
		padding-top: getRem(24);
		border-top: 1px solid #DADADA;
	}

    .subHeader {
        color: var(--red);
        @include font(24, 36);
        font-weight: 700;
        margin: getRem(32) 0 0;

        @include breakpoint(mobile) {
            @include font(18, 36);
            margin-top: 0;
        }
    }

    h1 {
        margin: getRem(12) 0 0;
    }

    p {
        @include font(18, 36);
        margin: getRem(12) 0 0;

        @include breakpoint(mobile) {
            @include font(16, 24);
        }

        &.yellowText {
            background: var(--yellow);
            display: inline-block;
            font-weight: 700;
            padding: 0 getRem(8);
        }

        a {
            color: var(--red);
            @include font(18, 36);
            font-weight: 700;
        }
    }
}

.sectionsContainer {
    background-color: var(--light-grey);
    position: relative;
    z-index: 1;
}

.librarySection {
    background-color: var(--light-grey);
    padding: getRem(40) 0;
    position: relative;

    h2 {
        margin: 0;
    }
}

.creativeFiltersSection {
	background-color: #fff;

	select {
		background-color: #fff;
	}
}

div.gridPage.landingPage {
    .librarySection {
        z-index: 1;
    }
}

div.gridPage.cePage {
    .librarySection {
        background-color: transparent;
    }
}


.filtersToggle {
	width: 100%;
    margin-bottom: getRem(24);
}

.filtersToggleBtn {
	@include font(21, 26);
	font-weight: 700;
	background-color: transparent;
	border: 0;
	appearance: none;
}

.filtersWrapper {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	flex-wrap: wrap;
	gap: 0 getRem(10);

	@include breakpoint(laptop) {
		margin-bottom: getRem(40);
	}

	@include breakpoint(mobile) {
		margin-bottom: getRem(20);
	}
}

.filtersContainer {
	flex: 0 0 calc(33% - getRem(10));
	margin-bottom: getRem(20);

	@include breakpoint(tablet) {
		flex: 0 0 calc(50% - getRem(10));
	}

	@include breakpoint(mobile) {
		flex: 0 0 100%;
	}

    label {
        display: block;
        @include font(14, 18);
        letter-spacing: getRem(1.75);
        text-transform: uppercase;
    }

    select {
        width: 100%;
        height: getRem(48);
        border: 1px solid var(--black);
        border-radius: getRem(2);
        display: inline-block;
        @include font(18, 28);
        margin-top: getRem(10);
        padding: 0 getRem(12);

        @include breakpoint(mobile) {
            width: 100%;
            min-width: 0;
        }
    }
}

.filterBtns {
	flex: 0 0 calc(33% - getRem(10));
	display: flex;
	gap: getRem(10);
	margin-bottom: getRem(20);

	@include breakpoint(laptop) {
		flex: 0 0 calc(50% - getRem(10));
	}

	@include breakpoint(mobile) {
		flex: 0 0 100%;
		flex-direction: column;
		gap: getRem(6);
	}
}

.filtersClearBtn {
	@include font(18, 20);
	text-decoration: underline;
	color: #000;
	margin: 0 0 getRem(40) 0;
	padding: 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	appearance: none;

	&:hover {
		text-decoration: none;
		cursor: pointer;
	}
}

.filterResultsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: getRem(32);
	margin-block: getRem(24) getRem(20);
	padding-top: getRem(20);
	border-top: 1px solid #D6D6D6;

	@include breakpoint(mobile) {
        flex-direction: column;
		align-items: flex-start;
		gap: 0;
    }
}

.filterResults {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: getRem(16);
	width: 100%;
}

.filterResultsOptions {
	@include font(20, 28);
	max-width: getRem(650);
	font-weight: 700;
}

.filterResultsOption {

	&::after {
		content: ', ';
	}

	&:last-child {
		&::after {
			content: '';
			margin-left: 0;
		}
	}
}

.filterResultsNumber {
	white-space: nowrap;
}

.shareButtonContainer {

	& > button {

		@include breakpoint(mobile) {
			margin: 0;
		}

		svg {
			margin-right: getRem(5);
	
			path {
				fill: #FFF;
				stroke: #FFF;
			}
		}
	}

	&:hover {
		& > button {
			color: #000;

			svg {
	
				path {
					fill: #000;
					stroke: #000;
				}
			}
		}
	}
}

.modalForm {
	padding: getRem(35) getRem(45);
	border: 1px solid #D6D6D6;

	@include breakpoint(mobile) {
		padding: getRem(20);
    }
}

.modalField {
	display: flex;
	align-items: center;
	margin-bottom: getRem(24);

	input {

		&:checked {

			& + label {
				font-weight: 700;
			}
		}
	}

	label {
		@include font(21, 26);
		text-transform: none;
	}
}

.modalBtns {
	display: flex;
	align-items: center;

	@include breakpoint(mobile) {
		flex-direction: column;
		align-items: flex-start;
		gap: getRem(16);
    }

	button {
		margin-bottom: 0;

		@include breakpoint(mobile) {
			margin: 0;
		}
	}
}

.modalBtnClear {
	@include font(20, 28);
	font-weight: 700;
	text-decoration: underline;
	color: #000;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	appearance: none;

	&:hover {
		cursor: pointer;
	}
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-gap: getRem(24) 1.33%;
    margin-top: getRem(40);

    @include breakpoint(tablet) {
        grid-template-columns: repeat(3, 32%);
        grid-gap: getRem(24) 2%;
    }

    @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;
        grid-gap: getRem(24) 0;
    }

    &.twoColumnGrid {
        grid-template-columns: repeat(2, calc(50% - 10px));
        grid-gap: getRem(24) getRem(20);
        margin-top: 0;

        @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
            grid-template-columns: 1fr;
            grid-gap: getRem(24) 0;
        }
    }

	&.fourColumnGrid {
		grid-template-columns: repeat(4, calc(25% - 10px));
        grid-gap: getRem(24) getRem(20);
        margin-top: 0;

        @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
            grid-template-columns: 1fr;
            grid-gap: getRem(24) 0;
        }
	}
}

.featuredContent {
    background-color: var(--light-grey);
    border-bottom: getRem(1) solid #ADADAD;
    padding: getRem(48) 0;

    .row {
        display: flex;

        @include breakpoint(tablet) {
            display: block;
        }
    }

    .imageContainer {
        width: 45.3%;
        height: auto;
        aspect-ratio: 1.64;
        background: var(--black);
        border-radius: getRem(8);
        flex-shrink: 0;
        overflow: hidden;
        position: relative;

        @include breakpoint(tablet) {
            width: 100%;
        }

        .playButton {
            width: 100%;
            height: 100%;
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            // opacity: 0;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;

            &:hover,
            &:focus {
                &+span img {
                    transform: scale(1.025);
                }
            }

            span {
                width: 1px;
                height: 1px;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            }

            svg {
                margin: auto;

                path {
                    fill: var(--white);
                    stroke: var(--white);
                }

                circle {
                    stroke: var(--white);
                    fill: rgba(255, 255, 255, .2);
                }
            }
        }
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        margin-left: getRem(32);
        padding-right: 2vw;

        @include breakpoint(tablet) {
            margin-top: getRem(24);
            margin-left: 0;
            padding-right: 0;
        }

        h3 {
            @include font(30, 36);
            margin: 0;
        }

        .excerpt {
            margin: getRem(16) 0 0;
        }

        a {
            color: var(--red);
            display: inline-block;
            @include font(20, 28);
            font-weight: 700;
            margin-top: getRem(18);
        }

        .actionsContainer {
            margin-top: auto;
        }

        .playLink {
            background: none;
            border: none;
            color: var(--red);
            cursor: pointer;
            display: inline-block;
            margin-right: auto;
            padding: 0;
        }
    }
}

.buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: getRem(8);
    margin: getRem(56) auto 0;
}

.creativePage {
    .banner {
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: calc(100% + 272px);
            background: url('/../../images/ce-banner-bg.svg') 0 100% no-repeat;
            background-size: cover;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }

        &>.wrapper {
            position: relative;
            z-index: 1;
        }
    }
}

.creativeFiltersPage {
	background-color: #fff;

	.banner {

		&::before {
			display: none;
		}
	}
}

.twoColumnCard {
    &>div {
        &:last-child {
            padding: getRem(16) getRem(24) getRem(24);
        }
    }
}